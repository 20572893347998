<template>
    <div class="categories_buttons animated slideInDown">
        <div class="categories_buttons__contain">
            <v-row>
                <div
                    class="category_item col"
                    v-for="item in special_categories"
                    :key="item.id"
                    @click="filterNews(item.id)"
                    :class="{'category_item_active': item.id == active_category}"
                >
                    <div class="item_icon">
                        <span class="material-icons-outlined item_icon--icon">
                            <span v-if="item.attributes.icon">{{item.attributes.icon}}</span>
                            <span class="material-icons" v-else>priority_high</span>
                        </span>
                    </div>
                    <div class="category_item__text">
                        {{item.attributes.name}}
                    </div>
                </div>
            </v-row>
        </div>
        <hr class="content__divider my-0 mt-4 mb-4"/>
    </div>
</template>
<script>
import "animate.css"
export default {
    props: {
        active_category: String,
        special_categories: Array
    },
    data(){
        return{
            active: null,
        }
    },
    methods: {
        filterNews(category){
            this.$emit('filterNews', category)
        }
    }
}
</script>