<template>
  <v-app >
    <Navigation/>
    <v-main class="news" v-if="charge">
      <v-container class="px-lg-6 px-sm-0 py-lg-8 py-sm-0 py-ms-0">
        <router-view></router-view>
        <div class="content" transition="scroll-y-reverse-transition" :style="{borderTopColor:theme.primaryColor} ">
          <div class="content__header mb-0">
            <v-row>
              <h1 class="content__header--title mb-2 col-6">
                Noticias
              </h1>
              <div class="col-6 pt-1">
                <button
                  @click="show_categories = !show_categories"
                  class="dropdown_button float-right py-3 pr-3"
                  :class="{'buttom_tabs--active': show_categories}"
                  v-ripple="{ center: true }"
                >
                  <span
                    class="dropdown_button--text"
                    :class="{'buttom_tabs--active': show_categories}"
                  >
                    Categorías
                  </span>
                    <ArrowIcon v-if="!show_categories" style="transform: rotate(180deg)" />
                    <ArrowIcon v-else color="#FFF" />
                </button>
              </div>
            </v-row>
          </div>
          <hr class="content__divider my-0 mb-4"/>

          <Categories
            v-if="show_categories"
            v-on:filterNews="findNewByCategory"
            :active_category="active_category"
            :special_categories="special_categories"
          />

          <h3 class="mb-2 content__subtitle">Generales</h3>

          <!--<div class="alert alert-default mb-8">
            {{twits[0].publication_date}} <span class="alert__text--divider"></span> <strong>{{twits[0].name}}</strong>
          </div>-->

          <!--<v-row>
            <v-col>
              <a
                href="javascript:;"
                class="buttom_tabs buttom--block"
                :class="{'buttom_tabs--active': !showVideos}"
                @click="showVideos = !showVideos"
                v-ripple="{ center: true }"
                :style="{'color': hoverTabs ? '#ffffff' : '#808191', 'background-color': hoverTabs ? theme.primaryColor: '#FFFFFF'}"
              >
                Noticias
              </a>
            </v-col>
            <v-col>
              <a
                href="javascript:;"
                class="buttom_tabs buttom--block"
                :class="{'buttom_tabs--active': showVideos}"
                @click="showVideos = !showVideos"
                v-ripple="{ center: true }"
                :style="{'color': hoverTabs ? '#ffffff' : '#808191', 'background-color': hoverTabs ? theme.primaryColor: '#FFFFFF'}"
              >
                Videos
              </a>
            </v-col>
          </v-row>-->

          <div class="service row" v-if="!showVideos">
            <v-row>
              <template>
                <div
                  class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 pb-0"
                  v-for="item in news_data"
                  :key="item.id"
                >
                    <ServiceItemHorizontal
                      :title="item.name"
                      :description="item.description"
                      :date="item.created_at"
                      :theme="theme"
                      :img="item.url != undefined ? item.url : '//via.placeholder.com/300x200'"
                      :service_id="item.id.toString()"
                      button_text="noticia"
                      :category="item.category"
                    />

                </div>
              </template>


            </v-row>
          </div>
          <div
            class="service row"
            v-else
          >
            <!--<ProgressLinear title="Cargando videos" :v-if="!charge" />-->
            <v-row>
              <div
                class="col-sm-6 col-md-6 col-lg-6 col-xl-6"
                v-for="item in videos"
                :key="item.id"
              >
                  <VideoServiceItem
                    :title="item.name"
                    :description="item.description"
                    :date="item.created_at"
                    :url="item.url"
                    :theme="theme"
                    :category="item.special_name"
                    :service_id="item.id.toString()"
                  />
              </div>
            </v-row>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
  // @ is an alias to /src
  import Categories from '../components/CategoriesButtons'

  export default {
    name: 'Locations',
    data () {
      return {
        charge: false,
        news:[],
        videos:[],
        twits: [],
        active_section: "news",
        active_category: "",
        active_twit: 0,
        page_normal: 1,
        page_titular: 1,
        page_video: 1,
        total_page_normal: 1,
        total_page_titular: 1,
        total_page_video: 1,
        show_categories: false,
        theme:{
          primaryColor: '#5242CB',
          secundaryColor: '#f57970',
          service: 'news'
        },
        hoverTabs: false,
        showVideos: false,
        leaked_news: [],
        special_categories: [],
      }
    },
    components: {
      Categories
    },
    mounted(){
      this.findNews()
      this.getSpecialCategories()
      this.checkDevice()
    },
    methods:{
      findNews(){
        try {
          this.$http.get('app/articles/news_pagination',{
            headers:
            this.getUserToken() != '' ?
            {
                "Authorization": "Bearer " + this.getUserToken(),
                "X-Device-ID" : this.buildDeviceId(),
                "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
            } :
            {
              "X-Device-ID" : this.buildDeviceId(),
              "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
            },
            params:{
                pageN: this.page_normal,
                pageV: this.page_video,
                pageT: this.page_titular,
                company:{id:this.getCurrentCompany()}
            },
          }).then(function(response){
            console.log(response);
            this.charge = false;
            this.news = response.body.articles.normal;
            this.videos = response.body.articles.video;
            this.twits = response.body.articles.titular;
            this.total_page_normal = this.news.pagesN;
            this.total_page_titular = this.news.pagesT;
            this.total_page_video = this.news.pagesV;
            this.charge = true;
            if(response.body.meta != undefined && response.body.meta != null){
                if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
                }
            }
          }, function(response){
            if(response.body.meta != undefined && response.body.meta != null){
                if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
                }
            }
          })
        } catch (e) {
            alert(e.message)
        }
      },
      findNewByCategory(category_id){
        this.active_category = category_id
        this.leaked_news = this.news.filter(item => item.special_category_id == category_id);
      },
      getSpecialCategories(){
        try{
          this.$http.get('app/special_categories/index')
          .then(function(response){
            this.special_categories = response.body.data;
          })
        }catch (e) {
          alert(e.message)
        }
      }
    },
    computed: {
      news_data: function() {
        const news = this.active_category ? this.leaked_news : this.news;
        return news;
      }
    }
  }
</script>
<style scoped>
  .buttom_tabs--active{
    background: #5242CB 0% 0% no-repeat padding-box !important;
    color: #FFF !important;
  }
  .buttom_tabs:hover{
    background: #5242CB 0% 0% no-repeat padding-box !important;
    color: #FFF !important;
  }
</style>
